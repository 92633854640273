import React from 'react'
import {useDispatch} from 'react-redux'
import {Link, navigate} from '@reach/router'
import {Toggle} from '@quotefactory/ui'
import {Layout} from 'pages'
import PaceChart, {
  getBounds,
  sortByStat,
  PaceChartStatTabs,
} from 'components/account/PaceChart'
import {clearProviderIntegrations} from 'reducers/providerIntegrations'
import {useGetPaceQuery} from 'services/rtk-api'
import DashboardSelect from '../components/DashboardSelect'
import useKeyListener from '../hooks/use-key-listener'

let filteredPaceData = []

export default function Dashboard() {
  const reduxDispatch = useDispatch()

  const [relativeScale, setRelativeScale] = React.useState(true)
  const [sort, setSort] = React.useState('stat')
  const [stat, setStat] = React.useState('count')

  // Fetch pace data
  const {data: allPaceData = []} = useGetPaceQuery()

  // A press-any-key search filter
  const [search, setSearch] = React.useState('')
  const onKeyDown = (key, modifiers) => {
    if (key === 'Escape') {
      setSearch('')
      return
    }
    if (key === 'Delete' || key === 'Backspace') {
      setSearch((prev) => prev.slice(0, -1))
      return
    }
    // If the filtered list only has one account showing, then navigate
    // to the account detail with the Enter key.
    if (key === 'Enter' && filteredPaceData.length === 1) {
      navigate(`/account/${filteredPaceData[0].brokerId}`)
    }

    // Don't care about function keys or key combinations except "shift" for uppercase.
    if (key.length > 1 || (modifiers.length > 0 && modifiers[0] !== 'shift')) {
      return
    }
    setSearch((prev) => (prev === '' && key === ' ' ? prev : `${prev}${key}`))
  }
  useKeyListener(onKeyDown)
  const sortedPaceData = React.useMemo(() => {
    if (!allPaceData?.[0]) {
      return allPaceData
    }
    if (sort === 'stat') {
      return sortByStat({stat, data: [...allPaceData]})
    }
    if (!allPaceData[0]) {
      return allPaceData
    }
    return [...allPaceData].sort((a, b) => {
      if (a.Broker < b.Broker) return -1
      if (a.Broker > b.Broker) return 1
      return 0
    })
  }, [allPaceData, sort, stat])

  filteredPaceData = React.useMemo(() => {
    return sortedPaceData.filter((a) => {
      if (!search) return true
      const regex = new RegExp(search, 'ig')
      return a.Broker.match(regex)
    })
  })

  // Set the relative scale bounds.
  // Only consider the brokers we'll display.
  const bounds = React.useMemo(
    () =>
      relativeScale ? getBounds({stat, data: filteredPaceData}) : undefined,
    [filteredPaceData, relativeScale],
  )

  const resetAccountData = () => {
    reduxDispatch(clearProviderIntegrations())
  }

  return (
    <Layout>
      <div className="flex items-center py-2 px-4 xl:px-4 justify-between my-6">
        <div className="flex items-center gap-2">
          <h2>Dashboard</h2>
          <DashboardSelect />
        </div>
      </div>

      {search && (
        <div className="ml-6 text-11">
          <span className="text-green-500">search: </span>
          <span className="text-black-500">{search}</span>
        </div>
      )}

      <div className="flex justify-between border-t border-gray-400 pt-4">
        <PaceChartStatTabs stat={stat} setStat={setStat} />
        <div className="flex items-center space-x-6">
          <label className="flex items-center justify-between gap-1.5 font-semibold">
            <Toggle
              id="sort"
              name="sort"
              className="scale-90"
              checked={sort === 'alpha'}
              onChange={() =>
                setSort((prev) => (prev === 'stat' ? 'alpha' : 'stat'))
              }
            />
            <div
              className={
                sort === 'alpha'
                  ? 'text-black-500'
                  : 'text-black-100 hover:text-black-500'
              }>
              Sort by name
            </div>
          </label>
          <label className="flex items-center justify-between gap-1.5 font-semibold">
            <Toggle
              id="relative"
              name="relative"
              className="scale-90"
              checked={relativeScale}
              onChange={() => setRelativeScale(!relativeScale)}
            />
            <div
              className={
                relativeScale
                  ? 'text-black-500'
                  : 'text-black-100 hover:text-black-500'
              }>
              Relative scale
            </div>
          </label>
        </div>
      </div>

      <div className="flex flex-wrap pt-4">
        {/* Combine all broker counts for an overall pace chart */}
        {!search && (
          <div className="p-4 w-80 rounded-md bg-blue-100 border border-blue-300">
            <div className="text-13 text-right font-bold">
              Quote Factory combined
            </div>
            <PaceChart stat={stat} />
          </div>
        )}

        {/* Show a pace chart for each broker */}
        {filteredPaceData.map((paceData) => (
          <div className="p-4 w-80 border border-transparent">
            <div className="text-13 text-right font-semibold">
              <Link
                to={`/account/${paceData.brokerId}`}
                onClick={resetAccountData}
                className="ml-4 text-13 hover:text-blue-500">
                {paceData.Broker}
              </Link>
            </div>
            <PaceChart
              accountId={paceData.brokerId}
              bounds={bounds}
              stat={stat}
            />
          </div>
        ))}
      </div>
    </Layout>
  )
}
