import React, {useState} from 'react'
import {Link} from '@reach/router'
import {useSelector} from 'react-redux'
import {SVG, PopperMenu, Divider, twMerge} from '@quotefactory/ui'
import {MenuMain} from 'components/nav'
import {UserAvatar} from 'components'

export default function Navbar() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const user = useSelector((state) => state.user)

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen)
  }

  return (
    <>
      <header className="fixed z-50 flex items-center justify-between h-[66px] ml:hidden space-x-1 w-full bg-purple-500">
        <button
          type="button"
          className="inline-flex items-center justify-center p-2 rounded-md text-purple-400 hover:text-white"
          aria-controls="mobile-menu"
          aria-expanded="false"
          onClick={toggleMobileMenu}>
          <span className="sr-only">Open main menu</span>
          {mobileMenuOpen ? (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          ) : (
            <svg
              className="block h-6 w-6"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
              onClick={toggleMobileMenu}>
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          )}
        </button>

        <div className="flex items-center px-4">
          <Link to="/dashboard" role="menuitem">
            <SVG name="logo" className="w-8 h-8 text-white" />
          </Link>
        </div>
      </header>

      <nav
        className={twMerge(
          'fixed border-l-2 before:absolute before:right-0 before:-top-0 before:-left-1 before:h-28 before:bg-gradient-to-b before:from-purple-300/50 before:via-purple-200/20 before:to-white before:border-t-2 before:border-purple-500 pt-[66px] ml:pt-0 top-0 left-0 bottom-0 bg-gradient-to-b from-white via-white to-gray-200 h-full w-full overflow-x-visible transition z-40 flex flex-col flex-grow-0 flex-shrink-0 ml:transition-none ml:translate-x-0 ml:w-[150px] xl:w-[200px]',
          mobileMenuOpen ? 'translate-x-0' : '-translate-x-full',
        )}>
        <div className="w-full ml:max-w-[200px] mx-auto h-screen flex flex-col">
          <div className="flex flex-col min-h-0 w-full p-4 pb-0">
            <div className="items-center justify-center p-4 mb-2 hidden ml:flex relative">
              <Link
                to="/dashboard"
                className="text-purple-500 hover:text-purple-300">
                <SVG name="logo" className="w-14 h-14" />
              </Link>
            </div>

            <Divider className="mt-3 mb-0 border-gray-200" />
          </div>

          <div className="overflow-y-auto flex-1 flex flex-col px-4 py-3 ">
            {/* <div className="flex flex-col gap-1 text-14">
              <div className="flex items-center justify-between relative rounded-full px-3 xl:px-4 -mx-2 xl:-mx-1 transition-all py-2 hover:bg-purple-100/50 text-purple-600 hover:text-purple-600 group">
                <Link
                  to="QFAdmin/Shipments/ShipmentsCover"
                  className="absolute inset-0 rounded-full"
                  aria-label="Shipments"
                />
                <span className="truncate">Cover</span>
                <span className="bg-purple-500 group-hover:bg-purple-400 text-white text-10 font-bold rounded-full px-[6px] py-[1px] ml-2">
                  4
                </span>
              </div>
            </div> */}

            {/* <Divider className="my-3 border-gray-200" /> */}

            <MenuMain />

            <div className="mt-auto py-2 flex flex-col w-full">
              <Divider className="mt-2 mb-3 border-gray-300" />

              <PopperMenu placement="top">
                <PopperMenu.Trigger className="w-full flex flex-1 items-center text-12 text-black-200 rounded-full pr-3 font-semibold hover:bg-gray-200 hover:text-black-700 group">
                  <UserAvatar
                    user={user}
                    className="w-[26px] mr-3"
                    size={1.6}
                  />
                  <div className="truncate">John Broker</div>
                  <SVG
                    name="arrowRight"
                    className="w-5 h-5 ml-auto -mr-3 text-gray-400 group-hover:text-gray-600 transition"
                  />
                </PopperMenu.Trigger>

                <PopperMenu.Popper className="w-full ml:w-[180px] z-50 !left-1 ">
                  <Link
                    to="/logout"
                    className="flex items-center gap-1 px-1 py-2 text-sm text-black-500 hover:text-red-500 hover:bg-gray-100"
                    role="menuitem">
                    <SVG name="logout" className="w-5" />
                    <div>Log out</div>
                  </Link>
                </PopperMenu.Popper>
              </PopperMenu>
            </div>
          </div>
        </div>
      </nav>
    </>
  )
}
