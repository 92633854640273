import * as React from 'react'
import {Popper} from '@quotefactory/ui'
import {SVG} from 'components'

/* An individual sort option */
function OptionItem({value, text, selected, onClick}) {
  return (
    <li className={`item ${selected ? 'active' : ''}`}>
      <button type="button" onClick={() => onClick(value)}>
        {text}
      </button>
    </li>
  )
}

function SortButton({title = 'Sort by', options = [], selectedSort, onChange}) {
  const [open, setOpen] = React.useState(false)

  const sortDir = selectedSort.split(':')[1]

  const handleMenuClose = (evt) => {
    setOpen(false)
  }

  const onSelection = (value) => {
    onChange(value)
    handleMenuClose()
  }

  const label =
    options.find((o) => o.value === selectedSort)?.label || 'Sort by'

  return (
    <Popper
      open={open}
      onOpenChange={() => {
        setOpen(($open) => !$open)
      }}
      placement="bottom-end">
      <div
        className={`sort-button min-w-0 my-4 ${selectedSort ? 'active' : ''} ${
          !open ? '' : 'focused'
        }`}>
        <Popper.Trigger
          type="button"
          className="truncate flex items-center"
          onClick={() => {
            setOpen(($open) => !$open)
          }}>
          <span className="text-black-100">
            Sort by: <span className="text-black-500">{label}</span>{' '}
          </span>
          <SVG
            name={sortDir === 'ASC' ? 'arrowAsc' : 'arrowDesc'}
            className="adornment text-black-500"
          />
        </Popper.Trigger>
      </div>

      <Popper.Content>
        <>
          <div className="flex items-center justify-between mb-4">
            <strong className="pr-4 text-base">{title}</strong>
            <button
              type="button"
              onClick={() => {
                setOpen(($open) => !$open)
              }}
              className="icon"
              aria-label="Close sort">
              <SVG name="close" className="w-5" />
            </button>
          </div>

          <ul className="menu-list -mx-2 w-60">
            {options.map(({value, text}) => (
              <OptionItem
                key={value}
                value={value}
                text={text}
                selected={value === selectedSort}
                onClick={onSelection}
              />
            ))}
          </ul>
        </>
      </Popper.Content>
    </Popper>
  )
}

export default SortButton
