import * as React from 'react'
import {Popper} from '@quotefactory/ui'
import {SVG} from 'components'

function FilterPill({
  label,
  title,
  active,
  clearFilter,
  closeOnChange,
  onFirstOpen,
  children,
}) {
  const [hasOpened, setHasOpened] = React.useState(false)
  const [open, setOpen] = React.useState(false)

  const handleActionClick = (evt) => {
    if (active) {
      evt.preventDefault()
      evt.stopPropagation()
      clearFilter()
      setOpen(false)
    }
  }

  React.useEffect(() => {
    if (closeOnChange) {
      setOpen(false)
    }
  }, [label])

  React.useEffect(() => {
    if (hasOpened) {
      onFirstOpen?.()
    }
  }, [hasOpened])

  return (
    <Popper
      placement="bottom-end"
      open={open}
      onOpenChange={() => {
        setOpen(($open) => !$open)
      }}>
      <Popper.Trigger
        className={`button-wrapper min-w-0 max-w-[160px] pl-4 flex items-center gap-2 ${
          active ? 'active' : ''
        } ${!open ? '' : 'focused'}`}
        onClick={() => {
          setOpen(($open) => !$open)
          setHasOpened(true)
        }}>
        <div className="truncate">{label} </div>
        <button
          type="button"
          onClick={handleActionClick}
          className="adornment mt-[1px]">
          {active ? (
            <SVG name="close" />
          ) : (
            <SVG name={open ? 'arrowUp' : 'arrowDown'} />
          )}
        </button>
      </Popper.Trigger>

      <Popper.Content>
        <div>
          <div className="flex items-center justify-between mb-4">
            <strong className="pr-4 text-base">{title}</strong>
            <button
              type="button"
              onClick={() => {
                setOpen(($open) => !$open)
              }}
              className="icon"
              aria-label="Close filter">
              <SVG name="close" className="w-5" />
            </button>
          </div>

          {children}
        </div>
      </Popper.Content>
    </Popper>
  )
}

export default FilterPill
