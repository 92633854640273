import * as React from 'react'
import {Chart} from 'react-google-charts'
import {format} from 'date-fns'
import {formatAsUSD, createNumberFormatterFn} from '@quotefactory/ui'
import {useGetPaceQuery} from 'services/rtk-api'

const formatNum = createNumberFormatterFn('en-US', {
  minimumFractionDigits: 0,
})

const formatByStat = (stat, value) =>
  ({
    count: formatNum(value),
    profit: formatAsUSD(value).slice(0, -3),
    price: formatAsUSD(value).slice(0, -3),
    cost: formatAsUSD(value).slice(0, -3),
  })[stat]

// Google Chart options
const getChartOptions = (bounds) => {
  const options = {
    legend: 'none',
    tooltip: {
      isHtml: true,
    },
    backgroundColor: {fill: 'transparent'},
    chartArea: {
      left: 32,
      top: 15,
      bottom: 22,
      width: '90%',
      height: '75%',
    },
    hAxis: {
      textStyle: {color: '#909eb2'},
      slantedText: false,
      slantedTextAngle: 78,
    },
    vAxis: {
      baselineColor: '#d8dfeb',
      gridlines: {count: 6, color: '#e4ebf5'},
      format: 'short',
    },
    series: {
      // This color becomes the color of the "certainty" (dashed) line
      // We then override the other segment colors in the row data
      0: {pointSize: 6, color: '#909eb2'},
    },
    animation: {
      startup: true,
      duration: 1000,
      easing: 'out',
    },
  }

  if (bounds) {
    options.vAxis.viewWindow = {
      min: bounds.min,
      max: bounds.max * 1.25,
    }
  }

  return options
}

// Label for the stat that shows on hover
const statLabels = {
  count: 'Shipments',
  profit: 'Profit',
  price: 'Price',
  cost: 'Cost',
}

// Define what each of the 4 columns in the data rows are about
const getColumnDefinitions = (stat) => [
  {id: '', label: 'Month', pattern: '', type: 'string'},
  {id: '', label: statLabels[stat], pattern: '', type: 'number'},
  // When certainty is false in the row, we get our dotted line
  {id: '', label: '', pattern: '', type: 'boolean', p: {role: 'certainty'}},
  {id: '', label: '', pattern: '', type: 'string', p: {role: 'style'}},
  {
    id: '',
    label: '',
    pattern: '',
    type: 'string',
    role: 'tooltip',
    p: {html: true},
  },
]

// Sum up all the broker counts to create an overall pace
export const sumOverallPace = (stat, data) => {
  if (!data?.[0]) return undefined
  const initialMonths = {}
  const monthKeys = data[0].months.map((month) => month.key)
  for (const month of data[0].months) {
    initialMonths[month.key] = month[stat]
  }
  const pace = data.slice(1).reduce(
    (acc, broker) => {
      for (const month of broker.months) {
        acc.months[month.key] += month[stat]
      }
      return acc
    },
    {Broker: 'Overall', months: initialMonths},
  )

  // Convert months object into an array
  const sortedMonths = []
  for (const monthKey of monthKeys) {
    sortedMonths.push({key: monthKey, [stat]: pace.months[monthKey]})
  }

  pace.months = sortedMonths

  return pace
}

// Find min & max count across all brokers
export const getBounds = ({stat, data}) =>
  data.reduce(
    (acc, broker) => {
      const stats = broker.months.map((month) => month[stat])
      stats.push(acc.min)
      stats.push(acc.max)
      return {
        min: Math.min(...stats),
        max: Math.max(...stats),
      }
    },
    {min: 0, max: 0},
  )

// Sort brokers from greatest to least in prior month
export const sortByStat = ({stat, data}) => {
  if (!data?.[0]) return data
  const priorMonthIdx = 1
  data.sort((a, b) => {
    if (a.months[priorMonthIdx][stat] < b.months[priorMonthIdx][stat]) return 1
    if (a.months[priorMonthIdx][stat] > b.months[priorMonthIdx][stat]) return -1
    if (a.months[0][stat] < b.months[0][stat]) return 1
    if (a.months[0][stat] > b.months[0][stat]) return -1
    return 0
  })
  return data
}

export default function PaceChart({accountId, stat = 'count', bounds, data}) {
  // Fetch pace data unless we were passed data
  const {data: allPaceData, isLoading} = useGetPaceQuery({}, {skip: !!data})

  const paceData = React.useMemo(() => {
    if (data) {
      return data
    }
    if (!allPaceData) {
      return {}
    }

    if (!accountId) {
      return sumOverallPace(stat, allPaceData)
    }

    // Find this broker's pace data in the list
    return allPaceData.find((p) => p.brokerId === accountId) || {}
  }, [accountId, stat, data, allPaceData])

  const chartColumns = getColumnDefinitions(stat)

  // Construct chart data for Google line chart
  const chartRows = React.useMemo(() => {
    if (!paceData?.months) return

    // Reverse the order of the months--chrono order
    const months = [...(paceData?.months || [])]?.reverse()
    const rows = []
    for (const month of months.slice(0, months.length - 1)) {
      rows.push({
        c: [
          {v: format(new Date(`${month.key}-02`), 'MMMMM'), f: null},
          {v: month[stat], f: null},
          {v: true, f: null},
          // Override the line color
          {v: 'color: #4388f7;', f: null},
          {
            v: [
              `<div class="p-2 text-15">${format(
                new Date(`${month.key}-02`),
                'MMMM',
              )}&nbsp;<b>${formatByStat(stat, month[stat])}</b></div>`,
            ],
            f: null,
          },
        ],
      })
    }
    const month = months.slice(-1)[0]
    rows.push({
      c: [
        {v: format(new Date(`${month.key}-02`), 'MMMMM'), f: null},
        {v: month[stat], f: null},
        {v: false, f: null}, // false "certainty" means we get a dashed line
        // Use the base line color for the dashed line.
        {v: null, f: null},
        {
          v: [
            `<div class="tooltip p-2 text-15">${format(
              new Date(`${month.key}-02`),
              'MMMM',
            )}&nbsp;<b>${formatByStat(stat, month[stat])}</b></div>`,
          ],
          f: null,
        },
      ],
    })

    return rows
  }, [stat, paceData])

  const chartData = {
    cols: chartColumns,
    rows: chartRows,
    p: null,
  }

  // If we got nothing, do nothing.
  if (!chartData || isLoading) {
    return null
  }

  // Render the Google chart
  return (
    <div className="[&_svg]:w-full">
      <Chart
        chartType="LineChart"
        width="100%"
        height="180px"
        data={chartData}
        options={getChartOptions(bounds)}
        columns={chartColumns}
      />
    </div>
  )
}

export function PaceChartStatTabs({stat, setStat}) {
  return (
    <ul className="flex w-fit gap-x-1 text-14 font-bold text-center text-gray-700 bg-gray-300/80 p-1 rounded-[7px] shadow-[inset_0_0_5px] shadow-gray-500/80">
      <li className="">
        <button
          type="button"
          onClick={() => setStat('profit')}
          className={`px-3 py-2 rounded-md ${stat === 'profit' ? 'bg-blue-500 text-white active' : 'text-black-100 hover:text-black-500 hover:bg-gray-100'}`}>
          Profit
        </button>
      </li>
      <li className="">
        <button
          type="button"
          onClick={() => setStat('price')}
          className={`px-3 py-2 rounded-md ${stat === 'price' ? 'bg-blue-500 text-white active' : 'text-black-100 hover:text-black-500 hover:bg-gray-100'}`}>
          Price
        </button>
      </li>
      <li className="">
        <button
          type="button"
          onClick={() => setStat('cost')}
          className={`px-3 py-2 rounded-md ${stat === 'cost' ? 'bg-blue-500 text-white active' : 'text-black-100 hover:text-black-500 hover:bg-gray-100'}`}>
          Cost
        </button>
      </li>
      <li className="">
        <button
          type="button"
          onClick={() => setStat('count')}
          className={`px-3 py-2 rounded-md ${stat === 'count' ? 'bg-blue-500 text-white active' : 'text-black-100 hover:text-black-500 hover:bg-gray-100'} `}>
          Count
        </button>
      </li>
    </ul>
  )
}
