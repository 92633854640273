import React from 'react'
import {
  SVG,
  ClickToCopy,
  TelephoneLink,
  EmailLink,
  Timestamp,
} from '@quotefactory/ui'
import {UserAvatar} from 'components'
import {useSetPrimaryUserMutation} from 'services/rtk-api'

export default function AccountUser({accountId, member, fetchTeam}) {
  const [setPrimaryUser] = useSetPrimaryUserMutation()

  if (!member) return null
  const {id, fullName, isOwner, isPrimary, username, phone, lastActiveAt} =
    member

  const setAsPrimary = async () => {
    await setPrimaryUser({accountId, userId: id})
    fetchTeam()
  }

  return (
    <div className="flex items-center gap-3 border-t border-gray-300 pt-3">
      <UserAvatar user={member} size={3} />
      <div>
        <div className="flex items-center text-15 font-semibold pb-0.5">
          {isOwner && (
            <button
              onClick={setAsPrimary}
              type="button"
              aria-label="Set as primary user">
              <SVG
                name="star"
                className={`-ml-6 w-5 h-5 shadow-[0_0_0_2px] shadow-white rounded-full bg-white ${isPrimary ? 'text-orange-500' : 'text-gray-300'}`}
              />
            </button>
          )}
          {fullName}
          <ClickToCopy
            className="text-gray-500 hover:text-black-500 ml-1"
            text={fullName}>
            <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          </ClickToCopy>
        </div>
        <div className="flex gap-1">
          <div
            className={`text-white font-bold tracking-wide px-1 py-px text-11 rounded ${
              isOwner ? 'bg-green-500' : 'bg-black-100'
            }`}>
            {isOwner ? 'Owner' : 'Member'}
          </div>

          <div className="px-1 py-px text-11 text-black-100 rounded mr-1 bg-gray-100">
            {lastActiveAt ? (
              <Timestamp timestamp={lastActiveAt} />
            ) : (
              <>No activity yet</>
            )}
          </div>
        </div>

        <div className="flex items-center gap-1">
          <EmailLink email={username}>{username}</EmailLink>
          <ClickToCopy
            className="text-gray-500 hover:text-black-500"
            text={username}>
            <SVG name="copy" className="-mt-0.5 w-5 h-5" />
          </ClickToCopy>
        </div>
        {phone && (
          <div className="flex items-center gap-1">
            <TelephoneLink phone={phone} className="whitespace-nowrap" />
            <ClickToCopy
              className="text-gray-500 hover:text-black-500"
              text={phone}>
              <SVG name="copy" className="-mt-0.5 w-5 h-5" />
            </ClickToCopy>
          </div>
        )}
      </div>
    </div>
  )
}
