/* eslint-disable react/require-default-props */
import * as React from 'react'
import isEqual from 'react-fast-compare'
import {SVG, Tooltip} from '@quotefactory/ui'

/**
 * @typedef Props
 *
 * @prop {string | (string | undefined)[]} [error]
 * @prop {boolean | (boolean | undefined)[]} [touched]
 * @prop {boolean} [isOverlayed]
 */

/**
 * @param {Props} props
 */
function QFErrorPopper(props) {
  const {error, touched = true, isOverlayed = false} = props

  const errorArray = Array.isArray(error) ? error : [error]
  const touchedArray = Array.isArray(touched) ? touched : [touched]

  const anyTouchedWithError = errorArray.some((_, i) => {
    return touchedArray[i] && errorArray[i]
  })

  if (!anyTouchedWithError) return null

  return (
    <Tooltip placement="top-end">
      <Tooltip.Trigger className={`error-icon ${isOverlayed ? 'overlay' : ''}`}>
        <SVG name="error" className="inline text-red-500" />
      </Tooltip.Trigger>

      <Tooltip.Content>
        <div className="max-w-xs">
          {errorArray
            .filter((err, i) => err && touchedArray[i])
            .map((err, i) => {
              return <div key={err}>{err}</div>
            })}
        </div>
      </Tooltip.Content>
    </Tooltip>
  )
}

export default React.memo(QFErrorPopper, isEqual)
