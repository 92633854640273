import React from 'react'
// import {Link, navigate} from '@reach/router'
import {SVG, Toggle} from '@quotefactory/ui'
import {Avatar} from 'components'
import PaceChart, {
  PaceChartStatTabs,
  getBounds,
  sortByStat,
  sumOverallPace,
} from 'components/account/PaceChart'
import {useGetAccountPaceQuery} from 'services/rtk-api'

const sortByRepName = (a, b) => {
  if (a.rep.name < b.rep.name) return -1
  if (a.rep.name > b.rep.name) return 1
  return 0
}

export default function RepsPace({account}) {
  const accountId = account.id
  const {name, teams} = account

  const hasAgencies = teams.some((t) => t.agency?.name)

  const [relativeScale, setRelativeScale] = React.useState(true)
  const [sort, setSort] = React.useState('stat')
  const [stat, setStat] = React.useState('profit')
  const [agenciesOnly, setAgenciesOnly] = React.useState(hasAgencies)

  // A list of the team names that are agencies (MF specific)
  const agencyNames = React.useMemo(
    () => teams.map((t) => t.agency?.name).filter(Boolean),
    teams,
  )

  const teamsNameMap = {}
  for (const team of teams) {
    teamsNameMap[team.name] = team
  }

  const {data: paceData = {teams: [], users: []}} = useGetAccountPaceQuery({
    accountId,
  })

  const repTeams = (
    sort === 'stat'
      ? sortByStat({stat, data: [...paceData.teams]})
      : [...paceData.teams].sort(sortByRepName)
  ).filter((t) => (agenciesOnly ? agencyNames.includes(t.rep.name) : true))

  let repUsers = []
  if (!agenciesOnly) {
    repUsers =
      sort === 'stat'
        ? sortByStat({stat, data: [...paceData.users]})
        : [...paceData.users].sort(sortByRepName)
  }

  const filteredData = [...repTeams, ...repUsers]

  // Set the relative scale bounds.
  const bounds = React.useMemo(
    () => (relativeScale ? getBounds({stat, data: filteredData}) : undefined),
    [stat, repTeams, repUsers],
  )

  // Define the data for the overall chart
  // Either all data for the broker or only the sum of the agency data
  const overallData = agenciesOnly
    ? sumOverallPace(stat, filteredData)
    : paceData.pace

  return (
    <>
      <div className="flex items-center p-2 justify-between">
        <PaceChartStatTabs stat={stat} setStat={setStat} />
        <div className="flex items-center space-x-6 text-14">
          {hasAgencies && (
            <label className="flex items-center justify-between gap-1.5 font-semibold">
              <Toggle
                id="agenciesOnly"
                name="agenciesOnly"
                className="scale-90"
                checked={agenciesOnly}
                onChange={() => setAgenciesOnly(!agenciesOnly)}
              />
              <div
                className={
                  agenciesOnly
                    ? 'text-black-500'
                    : 'text-black-100 hover:text-black-500'
                }>
                Agencies Only
              </div>
            </label>
          )}
          <label className="flex items-center justify-between gap-1.5 font-semibold">
            <Toggle
              id="sort"
              name="sort"
              className="scale-90"
              checked={sort === 'alpha'}
              onChange={() =>
                setSort((prev) => (prev === 'stat' ? 'alpha' : 'stat'))
              }
            />
            <div
              className={
                sort === 'alpha'
                  ? 'text-black-500'
                  : 'text-black-100 hover:text-black-500'
              }>
              Sort by name
            </div>
          </label>
          <label className="flex items-center justify-between gap-1.5 font-semibold">
            <Toggle
              id="relative"
              name="relative"
              className="scale-90"
              checked={relativeScale}
              onChange={() => setRelativeScale(!relativeScale)}
            />
            <div
              className={
                relativeScale
                  ? 'text-black-500'
                  : 'text-black-100 hover:text-black-500'
              }>
              Relative scale
            </div>
          </label>
        </div>
      </div>

      <div className="flex flex-wrap px-2">
        <div className="p-2 w-80 rounded-md bg-blue-100 border border-blue-300">
          <div className="text-13 flex items-center gap-2 font-semibold">
            {name}{' '}
            {agenciesOnly && (
              <span className="text-black-300 text-11">agencies only</span>
            )}
          </div>
          <PaceChart stat={stat} data={overallData} />
        </div>

        {repTeams.map((data) => (
          <div className="p-4 w-80 bg-gray-300/40 border border-transparent ">
            <div className="text-13 flex items-center gap-2 font-semibold">
              {teamsNameMap[data.rep.name]?.photoUrl ? (
                <Avatar
                  url={teamsNameMap[data.rep.name]?.photoUrl}
                  title={data.rep.name}
                  size={1.8}
                />
              ) : (
                <SVG
                  name="teams"
                  className="w-7 h-7 border border-gray-500 text-black-300 rounded-full p-1"
                />
              )}
              {data.rep.name}
            </div>
            <PaceChart data={data} bounds={bounds} stat={stat} />
          </div>
        ))}

        {!agenciesOnly &&
          repUsers.map((data) => (
            <div className="p-4 w-80 bg-transparent border border-transparent">
              <div className="text-13 flex items-center gap-2 font-semibold">
                <SVG
                  name="users"
                  className="w-7 h-7 border border-gray-500 text-black-300 rounded-full p-1"
                />
                {data.rep.name}
              </div>
              <PaceChart data={data} bounds={bounds} stat={stat} />
            </div>
          ))}
      </div>
    </>
  )
}
